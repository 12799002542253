import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Title from '../layout/Title';
import ButtonRow from '../layout/ButtonRow';
import PRow from '../layout/PRow';
//import Auth0Logo from '../../assets/images/Auth0Logo.png';
import StandardButton from '../buttons/StandardButton';

const MFACallbackPage = () => {
    // eslint-disable-next-line no-undef
    let finalUrl = `${process.env.REACT_APP_TSCOM_URL}`;
    return (
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body">
                            <Container>
                                <Title showLock={false}>
                                    <h1><strong>You have successfully upgraded to two-factor authentication</strong></h1>
                                </Title>
                                <PRow>
                                    <p>
                                        Congratulations! You now have the latest version of TradeStation two-factor authentication.
                                    </p>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={finalUrl}>
                                        <strong>Launch TradeStation.com</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default MFACallbackPage;
