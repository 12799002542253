import React, {useState} from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import Title from '../layout/Title';
import ButtonRow from '../layout/ButtonRow';
import PRow from '../layout/PRow';
import GuardianAppleAppIcon from '../icons/GuardianAppleAppIcon';
import GuardianGoogleAppIcon from '../icons/GuardianGoogleAppIcon';
import Auth0Logo from '../../assets/images/Auth0Logo.png';
import StandardButton from '../buttons/StandardButton';
import StandardInternalButton from '../buttons/StandardInternalButton';
import useQuery from '../../hooks/usQuery'

const GracePeriodPage = () => {
    let query = useQuery();
    // eslint-disable-next-line no-undef
    let skipQueryString = query.get("state") ? `${process.env.REACT_APP_SET_UP_URL}/continue?state=${query.get("state")}&setup_mfa=0` : `${process.env.REACT_APP_SET_UP_URL}/continue?setup_mfa=0`;
    // eslint-disable-next-line no-undef
    let setupQueryString = query.get("state") ? `${process.env.REACT_APP_SET_UP_URL}/continue?state=${query.get("state")}&setup_mfa=1` : `${process.env.REACT_APP_SET_UP_URL}/continue?setup_mfa=1`;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {e.preventDefault(); setShow(true)};
    return (
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body">
                            <Container>
                                <Title showLock={true}>
                                    <h1><strong>Psst… You still need to set up your two-factor authentication!</strong></h1>
                                </Title>
                                <PRow>
                                    <p>
                                        Just a little nudge to remind you that you have to upgrade your login to two-factor authentication. Please set up your new security so that you are able to log in without interruption.
                                    </p>
                                </PRow>
                                <PRow>
                                    <p>
                                        We’re committed to providing you with robust security and thank you for partnering with us to make your login more secure.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">What do I need to do?</h2>
                                    <p>
                                        We strive to keep your account safe. Two-factor authentication provides added security that ensures performance and reliability.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">What do I need to do?</h2>
                                    <p>
                                        Download the <img className="text-icon" src={Auth0Logo} alt="Auth0 icon" /> Auth0 Guardian app on your mobile device from the 
                                        <GuardianAppleAppIcon />  or <GuardianGoogleAppIcon />
                                        
                                        Make sure to have both your computer and mobile device available. When you are ready, click the <strong>Set Up Now</strong> button.
                                    </p>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="secondary" href={skipQueryString}>
                                        <strong>Skip for Now</strong>
                                    </StandardButton>
                                    <StandardInternalButton type="primary" onClickFunction={handleShow}>
                                        <strong>Set Up Now</strong>
                                    </StandardInternalButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <h2><strong>Let&apos;s get started</strong></h2>
                        <p>
                            Setting up your two-factor authentication takes a few minutes. Please make sure you have the time to do it now.
                            <br /><br />
                            Once you begin the process, you’ll need to finish it in order to log in. 
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row flex-wrap justify-content-center">
                    <StandardButton type="secondary" variant="secondary" href={skipQueryString}>
                        <strong>Skip for now</strong>
                    </StandardButton>
                    <StandardButton type="primary" variant="primary" href={setupQueryString}>
                        <strong>Yes I&apos;m Ready to Set Up</strong>
                    </StandardButton>
                </Modal.Footer>
            </Modal>
        </section>
    )
};

export default GracePeriodPage;
