import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Title from '../layout/Title';
import ButtonRow from '../layout/ButtonRow';
import PRow from '../layout/PRow';
import GuardianAppleAppIcon from '../icons/GuardianAppleAppIcon';
import GuardianGoogleAppIcon from '../icons/GuardianGoogleAppIcon';
import Auth0Logo from '../../assets/images/Auth0Logo.png';
import StandardButton from '../buttons/StandardButton';

const MFAEnrollmentPage = () => {
    // eslint-disable-next-line no-undef
    let finalUrl = `${process.env.REACT_APP_SET_UP_URL}/authorize?client_id=${process.env.REACT_APP_MFA_ENROLLMENT_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_MFA_ENROLLMENT_REDIRECT_URI}&state=Il9oFRUZpQhdyMny2tlBQYA7tBN7.NZD&nonce=-xKXPvRpNuMfsGvg-G1.jUrBt9.J_eKO`;
    // eslint-disable-next-line no-undef
    let clientCenterUrl = `${process.env.REACT_APP_CLIENT_CENTER_URL}`;
    return (
        <section className="container">
            <Row>
                <Col sm={{span: 12}} lg={{span: 11, offset: 1}} className="mb-5">
                    <div className="card text-font">
                        <div className="card-body">
                            <Container>
                                <Title showLock={true}>
                                    <h1><strong>Upgrade your login to two-factor authentication</strong></h1>
                                </Title>
                                <PRow>
                                    <p>
                                        As part of TradeStation’s commitment to provide robust security, we made updates that require you to use two-factor authentication.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">Why do I need to do this?</h2>
                                    <p>
                                        We strive to keep your account safe. Two-factor authentication provides added security that ensures performance and reliability.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">What do I need to do?</h2>
                                    <p>
                                        Download the <img className="text-icon" src={Auth0Logo} alt="Auth0 icon" /> Auth0 Guardian app on your mobile device from the 
                                        <GuardianAppleAppIcon />  or <GuardianGoogleAppIcon />
                                        
                                        Make sure to have both your computer and mobile device available. When you are ready, click the <strong>Set Up Now</strong> button.
                                    </p>
                                </PRow>
                                <PRow>
                                    <h2 className="mt-3 mb-3">How do I know that this is legitimate?</h2>
                                    <p>
                                        This upgrade is also accessible in the secure&nbsp; 
                                        <a href={clientCenterUrl} target="_blank" rel="noopener noreferrer">
                                            TradeStation Client Center
                                        </a>.&nbsp;
                                        You will initiate the upgrade with your unique login.
                                    </p>
                                </PRow>
                                <ButtonRow>
                                    <StandardButton type="primary" href={finalUrl}>
                                        <strong>Set Up Now</strong>
                                    </StandardButton>
                                </ButtonRow>
                            </Container>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
};

export default MFAEnrollmentPage;
