/* eslint-disable react/no-children-prop */
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
  
import Header from './components/layout/Header';
import NewUserPage from './components/pages/NewUserPage';
import SecurityNotice from './components/pages/SecurityNotice';
import SecurityNotice2FA from './components/pages/SecurityNotice2FA';
import MFAEnrollmentPage from './components/pages/MFAEnrollmentPage';
import MFACallbackPage from './components/pages/MFACallbackPage';
import GracePeriodPage from './components/pages/GracePeriodPage';

import { UsersProvider } from "./context/UsersContext";


const App = () => {
    return (
        <UsersProvider>
        <div className="app">
                <Header />
                <Router>
                    <Switch>
                        <Route exact={ true } path="/security-notice" sensitive={ true } children={<SecurityNotice />} />
                        <Route exact={ true } path="/security-notice-2fa" sensitive={ true } children={<SecurityNotice2FA />} />
                        <Route exact={ true } path="/new-user" sensitive={ true } children={<NewUserPage />} />
                        <Route exact={ true } path="/mfa-enrollment" sensitive={ true } children={<MFAEnrollmentPage />} />
                        <Route exact={ true } path="/mfa-callback" sensitive={ true } children={<MFACallbackPage />} />
                        <Route exact={ true } path="/grace-period" sensitive={ true } children={<GracePeriodPage />} />
                        <Route path="*" component={() => {window.location="https://www.tradestation.com"; return null}} />
                    </Switch>
                </Router>
            </div>
        </UsersProvider>
  );
};

export default App;
